import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Typography,
  Card,
  Stack,
  TextField,
  Button,
  Grid,
  Alert,
  Avatar,
  Tab,
  Tabs,
} from '@mui/material';
import {
  Save as SaveIcon,
  Security as SecurityIcon,
  AccountCircle as ProfileIcon,
} from '@mui/icons-material';
import { useMutation } from '@tanstack/react-query';
import { useAuth } from '../hooks/useAuth';
import { ApiClient } from '../api/client';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`settings-tabpanel-${index}`}
      aria-labelledby={`settings-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ py: 3 }}>{children}</Box>}
    </div>
  );
}

const AccountSettings = () => {
  const { user, updateUser } = useAuth();
  const apiClient = ApiClient.getInstance();
  
  const [activeTab, setActiveTab] = useState(0);
  const [notification, setNotification] = useState<{
    type: 'success' | 'error' | 'info';
    message: string;
  } | null>(null);

  // Profile form state
  const [profileForm, setProfileForm] = useState({
    username: '',
    email: '',
  });

  // Password form state
  const [passwordForm, setPasswordForm] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  });

  // Set initial form values from user data
  useEffect(() => {
    if (user) {
      setProfileForm({
        username: user.username || '',
        email: user.email || '',
      });
    }
  }, [user]);

  // Form validation
  const [formErrors, setFormErrors] = useState({
    username: '',
    email: '',
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  });

  // Add mutation for updating profile
  const updateProfileMutation = useMutation({
    mutationFn: (data: { username?: string }) => 
      apiClient.updateProfile(data),
    onSuccess: (data) => {
      // Update the user context with new data
      updateUser({
        username: profileForm.username,
      });
      
      setNotification({
        type: 'success',
        message: 'Profile updated successfully',
      });
      setTimeout(() => setNotification(null), 3000);
    },
    onError: (error) => {
      setNotification({
        type: 'error',
        message: 'Failed to update profile: ' + (error as Error).message,
      });
    }
  });

  // Add mutation for updating password
  const updatePasswordMutation = useMutation({
    mutationFn: (data: { currentPassword: string, newPassword: string }) => 
      apiClient.changePassword(data.currentPassword, data.newPassword),
    onSuccess: () => {
      setPasswordForm({
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
      });
      
      setNotification({
        type: 'success',
        message: 'Password updated successfully',
      });
      setTimeout(() => setNotification(null), 3000);
    },
    onError: (error) => {
      setNotification({
        type: 'error',
        message: 'Failed to update password: ' + (error as Error).message,
      });
    }
  });

  const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const handleProfileFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setProfileForm({
      ...profileForm,
      [name]: value,
    });
    
    // Clear error when user types
    if (formErrors[name as keyof typeof formErrors]) {
      setFormErrors({
        ...formErrors,
        [name]: '',
      });
    }
  };

  const handlePasswordFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setPasswordForm({
      ...passwordForm,
      [name]: value,
    });
    
    // Clear error when user types
    if (formErrors[name as keyof typeof formErrors]) {
      setFormErrors({
        ...formErrors,
        [name]: '',
      });
    }
  };

  const validateProfileForm = () => {
    const errors = {
      username: '',
      email: '',
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    };
    
    if (!profileForm.username) {
      errors.username = 'Username is required';
    }
    
    setFormErrors(errors);
    return !Object.values(errors).some(error => error);
  };

  const validatePasswordForm = () => {
    const errors = {
      username: '',
      email: '',
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    };
    
    if (!passwordForm.currentPassword) {
      errors.currentPassword = 'Current password is required';
    }
    
    if (!passwordForm.newPassword) {
      errors.newPassword = 'New password is required';
    } else if (passwordForm.newPassword.length < 8) {
      errors.newPassword = 'Password must be at least 8 characters';
    }
    
    if (!passwordForm.confirmPassword) {
      errors.confirmPassword = 'Please confirm your password';
    } else if (passwordForm.newPassword !== passwordForm.confirmPassword) {
      errors.confirmPassword = 'Passwords do not match';
    }
    
    setFormErrors(errors);
    return !Object.values(errors).some(error => error);
  };

  const handleSaveProfile = async () => {
    if (validateProfileForm()) {
      const changes: { username?: string } = {};
      
      // Only include fields that have changed
      if (user?.username !== profileForm.username) {
        changes.username = profileForm.username;
      }
      
      // Only make the API call if there are changes
      if (Object.keys(changes).length > 0) {
        updateProfileMutation.mutate(changes);
      } else {
        setNotification({
          type: 'success',
          message: 'No changes to save',
        });
        setTimeout(() => setNotification(null), 3000);
      }
    }
  };

  const handleSavePassword = async () => {
    if (validatePasswordForm()) {
      updatePasswordMutation.mutate({ 
        currentPassword: passwordForm.currentPassword, 
        newPassword: passwordForm.newPassword 
      });
    }
  };

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Typography variant="h4" gutterBottom>
        Account Settings
      </Typography>

      {notification && (
        <Alert severity={notification.type} sx={{ mb: 3 }}>
          {notification.message}
        </Alert>
      )}

      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <Card sx={{ p: 3, mb: 3 }}>
            <Stack spacing={2} alignItems="center">
              <Avatar
                sx={{
                  width: 100,
                  height: 100,
                  fontSize: 40,
                  bgcolor: 'primary.main',
                }}
              >
                {user?.username?.charAt(0).toUpperCase()}
              </Avatar>
              <Typography variant="h6">{user?.username}</Typography>
              <Typography variant="body2" color="text.secondary">
                {user?.email}
              </Typography>
            </Stack>
          </Card>

          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            orientation="vertical"
            sx={{ borderRight: 1, borderColor: 'divider' }}
          >
            <Tab icon={<ProfileIcon />} label="Profile" />
            <Tab icon={<SecurityIcon />} label="Security" />
          </Tabs>
        </Grid>

        <Grid item xs={12} md={9}>
          <Card sx={{ p: 3 }}>
            <TabPanel value={activeTab} index={0}>
              <Typography variant="h6" gutterBottom>
                Profile Information
              </Typography>
              <Stack spacing={3}>
                <TextField
                  label="Username"
                  name="username"
                  value={profileForm.username}
                  onChange={handleProfileFormChange}
                  fullWidth
                  error={!!formErrors.username}
                  helperText={formErrors.username}
                />
                <TextField
                  label="Email Address"
                  name="email"
                  type="email"
                  value={profileForm.email}
                  disabled
                  fullWidth
                  helperText="Email cannot be changed. Contact support for assistance."
                />
                <Button
                  variant="contained"
                  startIcon={<SaveIcon />}
                  onClick={handleSaveProfile}
                  disabled={true}
                >
                  {updateProfileMutation.isPending ? 'Saving...' : 'Save Profile'}
                </Button>
              </Stack>
            </TabPanel>

            <TabPanel value={activeTab} index={1}>
              <Typography variant="h6" gutterBottom>
                Security Settings
              </Typography>
              <Stack spacing={3}>
                <TextField
                  label="Current Password"
                  name="currentPassword"
                  type="password"
                  value={passwordForm.currentPassword}
                  onChange={handlePasswordFormChange}
                  fullWidth
                  error={!!formErrors.currentPassword}
                  helperText={formErrors.currentPassword}
                />
                <TextField
                  label="New Password"
                  name="newPassword"
                  type="password"
                  value={passwordForm.newPassword}
                  onChange={handlePasswordFormChange}
                  fullWidth
                  error={!!formErrors.newPassword}
                  helperText={formErrors.newPassword}
                />
                <TextField
                  label="Confirm New Password"
                  name="confirmPassword"
                  type="password"
                  value={passwordForm.confirmPassword}
                  onChange={handlePasswordFormChange}
                  fullWidth
                  error={!!formErrors.confirmPassword}
                  helperText={formErrors.confirmPassword}
                />
                <Stack direction="row" spacing={2}>
                  <Button
                    variant="contained"
                    startIcon={<SaveIcon />}
                    onClick={handleSavePassword}
                    disabled={updatePasswordMutation.isPending}
                  >
                    {updatePasswordMutation.isPending ? 'Updating...' : 'Update Password'}
                  </Button>
                </Stack>
              </Stack>
            </TabPanel>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AccountSettings;