import React, { createContext, useContext, useState, useCallback, useEffect, useRef } from 'react';
import { ApiClient } from '../api/client';
import { User } from '../types';

interface AuthContextType {
  user: User | null;
  isAuthenticated: boolean;
  isLoading: boolean;
  login: (token: string, userData?: Partial<User>) => Promise<void>;
  logout: () => void;
  updateUser: (userData: Partial<User>) => void;
  refreshAuth: () => Promise<void>;
}

export const AuthContext = createContext<AuthContextType>({
  user: null,
  isAuthenticated: false,
  isLoading: true,
  login: async () => {},
  logout: () => {},
  updateUser: () => {},
  refreshAuth: async () => {}
});

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const apiClient = ApiClient.getInstance();
  
  // Use refs to track refresh timer and avoid unnecessary refreshes
  const refreshTimerRef = useRef<number | null>(null);
  const refreshInProgressRef = useRef<boolean>(false);

  const fetchUserData = useCallback(async (token: string): Promise<User | null> => {
    if (!token) return null;
    
    try {
      const userData = await apiClient.getCurrentUser();
      return userData;
    } catch (error) {
      console.error("Failed to fetch user data:", error);
      return null;
    }
  }, [apiClient]);

  const setupRefreshTimer = useCallback(() => {
    // Clear any existing timer
    if (refreshTimerRef.current) {
      window.clearTimeout(refreshTimerRef.current);
    }

    // Set up a timer to refresh token before it expires (e.g., every 25 minutes if token lasts 30 minutes)
    refreshTimerRef.current = window.setTimeout(() => {
      refreshAuth();
    }, 25 * 60 * 1000); // 25 minutes
  }, []);

  const refreshAuth = useCallback(async () => {
    // Prevent multiple simultaneous refreshes
    if (refreshInProgressRef.current) return;
    
    const token = localStorage.getItem('token');
    if (!token) {
      setUser(null);
      setIsLoading(false);
      return;
    }
    
    refreshInProgressRef.current = true;
    setIsLoading(true);
    
    try {
      const refreshResult = await apiClient.refreshToken();
      if (refreshResult?.access_token) {
        localStorage.setItem('token', refreshResult.access_token);
        
        const userData = await fetchUserData(refreshResult.access_token);
        if (userData) {
          setUser(userData);
          // Set up the next refresh cycle
          setupRefreshTimer();
          return;
        }
      }
      
      throw new Error('Failed to refresh auth state');
    } catch (error) {
      console.error('Token refresh failed:', error);
      // Only clear token if we're sure it's invalid (e.g., server returned 401)
      if ((error as any)?.status === 401) {
        localStorage.removeItem('token');
        setUser(null);
      }
    } finally {
      refreshInProgressRef.current = false;
      setIsLoading(false);
    }
  }, [apiClient, fetchUserData, setupRefreshTimer]);

  const login = useCallback(async (token: string, userData?: Partial<User>) => {
    try {
      localStorage.setItem('token', token);
      setIsLoading(true);
      
      if (userData?.id) {
        setUser(userData as User);
      } else {
        const freshUserData = await fetchUserData(token);
        if (!freshUserData) {
          throw new Error('Failed to fetch user data');
        }
        setUser(freshUserData);
      }
      
      // Set up refresh timer after successful login
      setupRefreshTimer();
    } catch (error) {
      localStorage.removeItem('token');
      setUser(null);
      throw error;
    } finally {
      setIsLoading(false);
    }
  }, [fetchUserData, setupRefreshTimer]);

  const logout = useCallback(() => {
    // Clear token and auth state
    localStorage.removeItem('token');
    
    // Clear refresh timer
    if (refreshTimerRef.current) {
      window.clearTimeout(refreshTimerRef.current);
      refreshTimerRef.current = null;
    }
    
    setUser(null);
    setIsLoading(false);
    
    // Optional: Redirect to login page
    // window.location.href = '/login';
  }, []);

  const updateUser = useCallback((userData: Partial<User>) => {
    setUser((prev: User | null) => prev ? { ...prev, ...userData } : null);
  }, []);

  // Initial auth check on mount
  useEffect(() => {
    const initAuth = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        setIsLoading(false);
        return;
      }
      
      try {
        // First try to use the existing token
        const userData = await fetchUserData(token);
        if (userData) {
          setUser(userData);
          // Set up automatic refresh
          setupRefreshTimer();
        } else {
          // If getting user data fails, try refreshing the token
          await refreshAuth();
        }
      } catch (error) {
        console.error('Auth initialization failed:', error);
        // Try to refresh token if initial fetch fails
        try {
          await refreshAuth();
        } catch (refreshError) {
          // If everything fails, clear auth state
          localStorage.removeItem('token');
          setUser(null);
        }
      } finally {
        setIsLoading(false);
      }
    };
    
    initAuth();
    
    // Cleanup refresh timer on unmount
    return () => {
      if (refreshTimerRef.current) {
        window.clearTimeout(refreshTimerRef.current);
      }
    };
  }, [fetchUserData, refreshAuth, setupRefreshTimer]);

  // Event listener for storage changes (for multi-tab support)
  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === 'token') {
        // Token was changed in another tab
        if (!event.newValue) {
          // Token was removed, log out in this tab too
          setUser(null);
          if (refreshTimerRef.current) {
            window.clearTimeout(refreshTimerRef.current);
            refreshTimerRef.current = null;
          }
        } else if (event.newValue !== localStorage.getItem('token')) {
          // Token was updated, refresh auth state
          refreshAuth();
        }
      }
    };
    
    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [refreshAuth]);

  const value = {
    user,
    isAuthenticated: !!user,
    isLoading,
    login,
    logout,
    updateUser,
    refreshAuth
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};