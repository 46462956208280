import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useAuth } from '../../contexts/AuthContext';

interface PrivateRouteProps {
  children: React.ReactNode;
  requiredRoles?: string[];
}

export const PrivateRoute: React.FC<PrivateRouteProps> = ({ 
  children, 
  requiredRoles = []
}) => {
  const { isAuthenticated, isLoading, user } = useAuth();
  const location = useLocation();
  
  // Check if user has required role (if any are specified)
  const hasRequiredRole = requiredRoles.length === 0 || 
    (user?.role && requiredRoles.includes(user.role));

  // Handle loading state
  if (isLoading) {
    return (
      <Box 
        display="flex" 
        flexDirection="column"
        alignItems="center"
        justifyContent="center" 
        minHeight="100vh"
        gap={2}
      >
        <CircularProgress />
        <Typography variant="body2" color="text.secondary">
          Verifying your session...
        </Typography>
      </Box>
    );
  }

  // Handle authentication check
  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  // Handle role-based access
  if (!hasRequiredRole) {
    return (
      <Box 
        display="flex" 
        flexDirection="column"
        alignItems="center"
        justifyContent="center" 
        minHeight="100vh"
        gap={2}
      >
        <Typography variant="h6" color="error">
          Access Denied
        </Typography>
        <Typography variant="body1">
          You don't have permission to access this page.
        </Typography>
      </Box>
    );
  }

  // If all checks pass, render the protected content
  return <>{children}</>;
};