import axios, { AxiosInstance, AxiosResponse, AxiosError, InternalAxiosRequestConfig } from 'axios';

// Define error response type
interface ApiErrorResponse {
  message: string;
  error?: string;
  statusCode?: number;
}

// Extend the InternalAxiosRequestConfig to include our custom _retry property
interface CustomAxiosRequestConfig extends InternalAxiosRequestConfig {
  _retry?: boolean;
}

// Define public routes that don't require authentication
const publicRoutes = ['/auth/login', '/auth/register', '/surveys/public'];

// Keep track of token refresh attempts to prevent loops
let isRefreshing = false;
let refreshSubscribers: ((token: string) => void)[] = [];

export const createAxiosInstance = (baseURL: string): AxiosInstance => {
  const instance = axios.create({
    baseURL,
    headers: {
      'Content-Type': 'application/json',
    },
    timeout: 30000,  // Increased to 30 seconds default timeout
  });

  // Subscribe to token refresh
  const subscribeTokenRefresh = (cb: (token: string) => void) => {
    refreshSubscribers.push(cb);
  };

  // Notify subscribers about new token
  const onRefreshed = (token: string) => {
    refreshSubscribers.forEach(cb => cb(token));
    refreshSubscribers = [];
  };

  // Request interceptor
  instance.interceptors.request.use(
    (config) => {
      // Always include token for all requests except public routes
      if (config.url && !publicRoutes.some(route => config.url?.includes(route))) {
        const token = localStorage.getItem('token');
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  // Response interceptor
  instance.interceptors.response.use(
    (response: AxiosResponse) => {
      return response;
    },
    async (error: AxiosError<ApiErrorResponse>) => {
      // Cast the config to our custom type that includes _retry
      const originalRequest = error.config as CustomAxiosRequestConfig;
      
      if (!originalRequest) {
        return Promise.reject(error);
      }

      // Don't retry for auth endpoints or public routes
      const isAuthEndpoint = originalRequest.url?.includes('/auth/login') || originalRequest.url?.includes('/auth/register');
      const isPublicRoute = publicRoutes.some(route => originalRequest.url?.includes(route));
      
      // Handle 401 Unauthorized errors (token expired or invalid)
      if (error.response?.status === 401 && !isAuthEndpoint && !isPublicRoute && originalRequest.url !== '/auth/refresh') {
        // If this request was already retried once, don't try again
        if (originalRequest._retry) {
          localStorage.removeItem('token');
          window.location.href = `/login?redirect=${encodeURIComponent(window.location.pathname)}`;
          return Promise.reject(error);
        }

        if (!isRefreshing) {
          isRefreshing = true;
          originalRequest._retry = true;
          
          try {
            // Create a direct axios instance for refresh to avoid interceptor loop
            const refreshResponse = await axios.post(
              `${baseURL}/auth/refresh`, 
              {}, 
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem('token')}`
                }
              }
            );
            
            const { access_token } = refreshResponse.data;
            
            if (access_token) {
              // Store the new token
              localStorage.setItem('token', access_token);
              
              // Update request headers with new token
              if (originalRequest.headers) {
                originalRequest.headers.Authorization = `Bearer ${access_token}`;
              }
              
              // Notify all pending requests
              onRefreshed(access_token);
              
              // Retry the original request with new token
              return instance(originalRequest);
            } else {
              throw new Error('No access token received');
            }
          } catch (refreshError) {
            // Clear token and redirect to login
            localStorage.removeItem('token');
            
            // Only redirect if not already on login page
            if (!window.location.pathname.includes('/login')) {
              window.location.href = `/login?redirect=${encodeURIComponent(window.location.pathname)}`;
            }
            return Promise.reject(refreshError);
          } finally {
            isRefreshing = false;
          }
        } else {
          // Wait for token refresh to complete
          return new Promise((resolve) => {
            subscribeTokenRefresh((token: string) => {
              if (originalRequest.headers) {
                originalRequest.headers.Authorization = `Bearer ${token}`;
                resolve(instance(originalRequest));
              }
            });
          });
        }
      }
      
      return Promise.reject(error);
    }
  );

  return instance;
};