import axios from 'axios';
import { createAxiosInstance } from '../utils/axiosInterceptor';
import type { AxiosInstance } from 'axios';
import { AuthResponse, VerifyOtpResponse, ResendOtpResponse, RegisterResponse } from '../types/auth';
import { TeamPermissions } from '../services/teamService';
import { AIInsights, SurveyDesignAnalysis, SmartValidation, QuestionSuggestion } from '../types/ai';

interface User {
  id: string;
  username: string;
  email: string;
  role: string;
  lastActive?: Date;
}

interface LogicRule {
  id: string;
  sourceQuestionId: string;
  condition: string;
  operator: string;
  value: any;
  action: 'skip_to' | 'end' | 'show_message';
  target?: string;
  message?: string;
}

interface Question {
  id?: string;
  type: string;
  text: string;
  options?: string[];
  required: boolean;
  validation?: {
    min?: number;
    max?: number;
    scale?: number;
    allowedTypes?: string[];
  };
  styling?: {
    layout?: string;
    theme?: string;
    customCss?: string;
  };
  logicRules?: LogicRule[];
}

interface Survey {
  id: string;
  title: string;
  description?: string;
  questions: Question[];
  createdAt: string;
  updatedAt: string;
  isActive: boolean;
  responseCount: number;
  creator: User;
}

interface Response {
  id: string;
  surveyId: string;
  userId: string;
  answers: Answer[];
  timeSpent: number;
  completedAt: string;
}

interface Answer {
  questionId: string;
  value: string | number | boolean;
}

interface Analytics {
  overview: {
    totalResponses: number;
    completionRate: number;
    averageCompletionTime: number;
    responseRate: number;
  };
  questionAnalytics: Array<{
    questionId: string;
    questionText: string;
    responseCount: number;
    averageTimeSpent: number;
    answerDistribution: Record<string, number>;
    engagementScore: number;
    skippedCount: number;
  }>;
  deviceBreakdown: Record<string, number>;
  timeBasedAnalytics: {
    hourlyDistribution: Record<string, number>;
    dayOfWeekDistribution: Record<string, number>;
    dailyDistribution: Record<string, number>;
    responseTimeTrends: Array<{
      date: string;
      count: number;
    }>;
  };
  participantEngagement: {
    averageInteractions: number;
    mediaResponseRate: number;
    dropOffPoints: Record<string, number>;
  };
  aiInsights: {
    score: number;
    sentimentAnalysis: {
      positive: number;
      negative: number;
      neutral: number;
    };
    keywordAnalysis: string[];
    improvements: string[];
    patterns: {
      completionTime: {
        average: number;
        distribution: Array<{
          name: string;
          value: number;
        }>;
      };
      dropoffPoints: {
        questionIds: string[];
        rates: number[];
      };
    };
  };
  responses?: Array<{
    id: string;
    createdAt: string;
    isComplete: boolean;
    analytics?: {
      timeSpent: number;
      deviceInfo?: {
        device: string;
        browser: string;
        os: string;
      };
    };
  }>;
}

interface QuestionAnalytics {
  questionId: string;
  averageTimeSpent: number;
  answerDistribution: Record<string, number>;
  engagementScore: number;
}

interface LeaderboardUser {
  id: string;
  name: string;
  points: number;
  badges: string[];
  streak: number;
  avatar: string;
}

interface Team {
  id: string;
  name: string;
  description?: string;
  members: { id: string; name: string; email: string }[];
  memberPermissions: {
    [userId: string]: {
      canManageTeam: boolean;
      canInviteMembers: boolean;
      canRemoveMembers: boolean;
      canShareSurveys: boolean;
    };
  };
  sharedSurveys: {
    [surveyId: string]: {
      canEdit: boolean;
      canDelete: boolean;
      canViewResponses: boolean;
      canShare: boolean;
      addedBy: string;
      addedAt: Date;
    };
  };
}

interface Template {
  id: string;
  name: string;
  description: string;
  questions: any[];
  settings?: {
    theme?: string;
    layout?: string;
    branding?: {
      logo?: string;
      colors?: {
        primary?: string;
        secondary?: string;
      };
    };
  };
  tags: string[];
  analytics?: {
    usageCount: number;
    averageCompletionTime?: number;
    rating?: number;
  };
  creator: User;
  createdAt: Date;
  updatedAt: Date;
}

interface QuestionBankItem {
  id: string;
  text: string;
  type: string;
  options?: string[];
  validation?: {
    required?: boolean;
    min?: number;
    max?: number;
    pattern?: string;
  };
  categories: string[];
  metadata: {
    usageCount: number;
    rating: number;
    language: string;
    difficultyLevel?: 'basic' | 'intermediate' | 'advanced';
  };
  aiMetadata?: {
    suggestedFollowUps?: string[];
    relatedQuestions?: string[];
    sentiment?: 'neutral' | 'positive' | 'negative';
    topics?: string[];
  };
  creator: User;
}

interface PaginationParams {
  page?: number;
  limit?: number;
}

export class ApiClient {
  private static instance: ApiClient;
  private readonly baseUrl: string;
  private authToken: string | null = null;
  private axios: AxiosInstance;

  private constructor() {
    this.baseUrl = process.env.REACT_APP_API_URL || '';
    this.axios = createAxiosInstance(this.baseUrl);
  }

  static getInstance(): ApiClient {
    if (!ApiClient.instance) {
      ApiClient.instance = new ApiClient();
    }
    return ApiClient.instance;
  }

  setAuthToken(token: string | null) {
    this.authToken = token;
  }

  private getHeaders(): HeadersInit {
    const headers: HeadersInit = {
      'Content-Type': 'application/json',
    };

    if (this.authToken) {
      headers['Authorization'] = `Bearer ${this.authToken}`;
    }

    return headers;
  }

  private async handleResponse<T>(response: globalThis.Response): Promise<T> {
    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message || 'Something went wrong');
    }
    return response.json();
  }

  private async request<T>(endpoint: string, options: RequestInit = {}): Promise<T> {
    const response = await fetch(`${this.baseUrl}${endpoint}`, {
      ...options,
      headers: {
        'Content-Type': 'application/json',
        ...options.headers,
      },
    });

    if (!response.ok) {
      const error = await response.json().catch(() => ({ message: 'An error occurred' }));
      throw new Error(error.message || `HTTP error! status: ${response.status}`);
    }

    return response.json();
  }

  async login(credentials: { email: string; password: string }): Promise<AuthResponse> {
    const response = await fetch(`${this.baseUrl}/auth/login`, {
      method: 'POST',
      headers: this.getHeaders(),
      body: JSON.stringify(credentials),
    });
    return this.handleResponse(response);
  }

  async register(userData: { email: string; password: string; username: string }): Promise<RegisterResponse> {
    const response = await fetch(`${this.baseUrl}/auth/register`, {
      method: 'POST',
      headers: this.getHeaders(),
      body: JSON.stringify(userData),
    });
    return this.handleResponse(response);
  }

  async verifyOtp(verifyData: { email: string; otp: string }): Promise<VerifyOtpResponse> {
    const response = await fetch(`${this.baseUrl}/auth/verify-otp`, {
      method: 'POST',
      headers: this.getHeaders(),
      body: JSON.stringify(verifyData),
    });
    return this.handleResponse(response);
  }

  async resendOtp(data: { email: string }): Promise<ResendOtpResponse> {
    const response = await fetch(`${this.baseUrl}/auth/resend-otp`, {
      method: 'POST',
      headers: this.getHeaders(),
      body: JSON.stringify(data),
    });
    return this.handleResponse(response);
  }

  // Basic HTTP methods
  private async get(url: string, config?: any) {
    return this.axios.get(url, config);
  }

  private async post(url: string, data?: any, config?: any) {
    return this.axios.post(url, data, config);
  }

  private async put(url: string, data?: any, config?: any) {
    return this.axios.put(url, data, config);
  }

  private async delete(url: string, config?: any) {
    return this.axios.delete(url, config);
  }

  // Add public getter for axios instance
  public getAxiosInstance(): AxiosInstance {
    return this.axios;
  }

  // Auth endpoints
  async getCurrentUser() {
    try {
      const response = await this.axios.get('/auth/me');
      return response.data;
    } catch (error) {
      throw this.handleApiError(error, 'getCurrentUser');
    }
  }

  async refreshToken() {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No token available');
      }
      
      // Use the axios instance directly, not this.axios, to bypass interceptors
      // that might cause a refresh loop
      const response = await axios.post(
        this.axios.defaults.baseURL + '/auth/refresh',
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      
      // If successful, update the token in localStorage
      if (response.data?.access_token) {
        localStorage.setItem('token', response.data.access_token);
      }
      
      return response.data;
    } catch (error) {
      // If refresh fails with 401, clear the token
      if ((error as any).response?.status === 401) {
        localStorage.removeItem('token');
      }
      throw this.handleApiError(error, 'refreshToken');
    }
  }

  // Helper method to handle API errors consistently
  private handleApiError(error: any, endpoint: string): never {
    let errorMessage = 'An unknown error occurred';
    
    if (error.response?.data?.message) {
      errorMessage = error.response.data.message;
    } else if (error.response?.data?.error) {
      errorMessage = error.response.data.error;
    } else if (error.message) {
      errorMessage = error.message;
    }

    // If unauthorized, clean up token
    if (error.response?.status === 401) {
      localStorage.removeItem('token');
    }
    
    const enhancedError = new Error(`${errorMessage}`);
    (enhancedError as any).status = error.response?.status;
    throw enhancedError;
  }

  // Simple method to verify backend connectivity
  async checkConnection(): Promise<boolean> {
    try {
      const response = await this.axios.get('/auth/me', { 
        validateStatus: (status) => status < 500 // Accept any non-server error response
      });
      return true;
    } catch (error) {
      console.error('Backend connection check failed:', error);
      return false;
    }
  }

  // Survey endpoints
  async createSurvey(data: any) {
    try {
      const response = await this.axios.post('/surveys', data, {
        timeout: 60000 // 60 seconds timeout for survey creation specifically
      });
      return response.data;
    } catch (error) {
      throw this.handleApiError(error, 'createSurvey');
    }
  }

  async updateSurvey(id: string, data: any) {
    try {
      const response = await this.axios.put(`/surveys/${id}`, data);
      return response.data;
    } catch (error) {
      throw this.handleApiError(error, 'updateSurvey');
    }
  }

  async getSurvey(id: string) {
    try {
      const response = await this.axios.get(`/surveys/${id}`);
      return response.data;
    } catch (error) {
      throw this.handleApiError(error, 'getSurvey');
    }
  }

  async deleteSurvey(id: string) {
    const response = await this.axios.delete(`/surveys/${id}`);
    return response.data;
  }

  async getAllSurveys(params?: PaginationParams & { search?: string }) {
    const response = await this.axios.get('/surveys/admin/all', { params });
    return response.data;
  }

  async publishSurvey(id: string) {
    const response = await this.axios.post(`/surveys/${id}/publish`);
    return response.data;
  }

  async unpublishSurvey(id: string) {
    const response = await this.axios.post(`/surveys/${id}/unpublish`);
    return response.data;
  }

  // Method for fetching surveys created by the current user
  async getUserSurveys(params?: PaginationParams) {
    try {
      const response = await this.axios.get('/surveys/user/created', { params });
      return response.data;
    } catch (error) {
      console.error('Failed to fetch user surveys:', error);
      return { surveys: [], total: 0 }; // Return empty surveys array as fallback
    }
  }

  // Survey analytics endpoints
  async getSurveyAnalytics(surveyId: string, params?: { startDate?: string; endDate?: string }) {
    try {
      const queryParams = new URLSearchParams();
      if (params?.startDate) queryParams.append('startDate', params.startDate);
      if (params?.endDate) queryParams.append('endDate', params.endDate);

      const response = await this.axios.get(
        `/responses/survey/${surveyId}/analytics?${queryParams.toString()}`
      );
      return response.data;
    } catch (error) {
      console.error('Failed to fetch analytics:', error);
      return {
        overview: {
          totalResponses: 0,
          completionRate: 0,
          averageCompletionTime: 0,
          responseRate: 0
        },
        questionAnalytics: [],
        deviceBreakdown: {},
        timeBasedAnalytics: {
          hourlyDistribution: {},
          dayOfWeekDistribution: {},
          dailyDistribution: {},
          responseTimeTrends: []
        }
      };
    }
  }

  async getSurveyAIInsights(surveyId: string): Promise<AIInsights> {
    try {
      const response = await this.axios.get(`/responses/survey/${surveyId}/ai-insights`);
      // After fetching AI insights, also get the analysis stats to ensure we have the latest trigger counts
      const analysisStatsResponse = await this.getManualAnalysisStats(surveyId);
      
      return {
        ...response.data,
        analysisStats: {
          ...response.data.analysisStats,
          dailyTriggersUsed: analysisStatsResponse.dailyTriggersUsed,
          dailyTriggersRemaining: analysisStatsResponse.dailyTriggersRemaining,
          dailyTriggersLimit: analysisStatsResponse.dailyTriggersLimit || 3
        }
      };
    } catch (error) {
      console.error('Failed to fetch AI insights:', error);
      return {
        sentimentAnalysis: {
          data: { positive: 0, negative: 0, neutral: 0 },
          status: 'completed',
          timestamp: new Date().toISOString()
        },
        keywordAnalysis: [],
        improvements: [],
        patterns: {
          completionTime: { average: 0, distribution: {} },
          dropoffPoints: { questionIds: [], rates: [] }
        },
        score: 0,
        analysisStats: {
          totalResponses: 0,
          analyzedResponses: 0,
          lastAnalyzedAt: null,
          pendingAnalysis: 0,
          dailyTriggersUsed: 0,
          dailyTriggersRemaining: 3,
          dailyTriggersLimit: 3
        },
        status: 'no_insights'
      };
    }
  }

  async analyzeSurveyResponses(surveyId: string): Promise<AIInsights> {
    return this.request<AIInsights>(`/surveys/${surveyId}/analyze`);
  }

  async analyzeSurveyDesign(surveyId: string): Promise<SurveyDesignAnalysis> {
    return this.request<SurveyDesignAnalysis>(`/surveys/${surveyId}/validate-design`);
  }

  async generateSmartValidation(questionId: string): Promise<SmartValidation> {
    return this.request<SmartValidation>(`/questions/${questionId}/smart-validation`);
  }

  async getSuggestedQuestions(surveyId: string, context: {
    surveyType?: string;
    targetAudience?: string;
  }): Promise<QuestionSuggestion[]> {
    return this.request<QuestionSuggestion[]>(`/surveys/${surveyId}/suggest-questions`, {
      method: 'POST',
      body: JSON.stringify(context),
    });
  }

  async exportSurvey(surveyId: string, format: 'csv' | 'excel' = 'csv') {
    try {
      const response = await this.axios.get(`/surveys/${surveyId}/export`, {
        params: { format },
        responseType: 'blob'
      });
      return response.data;
    } catch (error) {
      console.error('Error exporting survey:', error);
      throw error;
    }
  }

  async createSurveyIteration(id: string, data?: Partial<Survey>) {
    try {
      const response = await this.axios.post(`/surveys/${id}/iterate`, data || {});
      return response.data;
    } catch (error) {
      throw this.handleApiError(error, 'createSurveyIteration');
    }
  }

  // Response endpoints
  async submitResponse(surveyId: string, data: any) {
    try {
      const response = await this.axios.post(`/responses/survey/${surveyId}`, data);
      return response.data;
    } catch (error) {
      this.handleApiError(error, 'submitResponse');
    }
  }

  async getSurveyResponses(surveyId: string, params?: { startDate?: string; endDate?: string }) {
    try {
      const queryParams = new URLSearchParams();
      if (params?.startDate) queryParams.append('startDate', params.startDate);
      if (params?.endDate) queryParams.append('endDate', params.endDate);

      const response = await this.axios.get(
        `/responses/survey/${surveyId}?${queryParams.toString()}`
      );
      return {
        responses: response.data.map((response: any) => ({
          id: response.id,
          createdAt: response.createdAt,
          isComplete: response.isComplete,
          analytics: {
            timeSpent: response.timeSpent || response.analytics?.timeSpent,
            deviceInfo: response.analytics?.deviceInfo
          }
        }))
      };
    } catch (error) {
      console.error('Failed to fetch survey responses:', error);
      return { responses: [] };
    }
  }

  // Method used in ViewResults.tsx - provides detailed results
  async getSurveyResults(surveyId: string) {
    try {
      const response = await this.axios.get(`/responses/survey/${surveyId}/results`);
      return response.data;
    } catch (error) {
      console.error('Failed to fetch survey results:', error);
      return { results: [], survey: { title: 'Unknown Survey' } };
    }
  }

  // Method used in ViewResults.tsx - exports results as CSV/XLS
  async exportSurveyResults(surveyId: string) {
    try {
      const response = await this.axios.get(`/responses/survey/${surveyId}/export`, {
        responseType: 'blob'
      });
      
      // Create a download link for the exported file
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `survey-results-${surveyId}.csv`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      
      return response.data;
    } catch (error) {
      console.error('Failed to export survey results:', error);
      throw error;
    }
  }

  async getUserResponses(params?: PaginationParams) {
    try {
      const response = await this.axios.get('/responses/user/my-responses', { params });
      return response.data;
    } catch (error) {
      console.error('Failed to fetch user responses:', error);
      return { surveys: [] }; // Return empty surveys array as fallback
    }
  }

  async getResponseAnalytics(surveyId: string) {
    try {
      const response = await this.axios.get(`/responses/survey/${surveyId}/analytics`);
      return response.data;
    } catch (error) {
      console.error('Failed to fetch response analytics:', error);
      return { 
        totalResponses: 0,
        averageCompletionTime: 0,
        responseRate: 0,
        questionAnalytics: []
      };
    }
  }

  async exportResponses(surveyId: string, format: 'csv' | 'excel' = 'csv') {
    try {
      const response = await this.axios.get(`/responses/survey/${surveyId}/export`, {
        params: { format },
        responseType: 'blob'
      });
      return response.data;
    } catch (error) {
      console.error('Failed to export responses:', error);
      throw error;
    }
  }

  // Template endpoints
  async createTemplate(data: any) {
    const response = await this.axios.post('/templates', data);
    return response.data;
  }

  async getTemplates(params?: { 
    creator?: string;
    tags?: string[];
    search?: string;
  }) {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Authentication required');
      }

      const response = await this.axios.get('/templates', {
        params: {
          ...params,
          search: params?.search?.trim() || undefined,
          tags: params?.tags?.length ? params.tags : undefined,
          creator: params?.creator || undefined
        }
      });
      return response.data || []; // Ensure we always return an array
    } catch (error) {
      if ((error as any)?.response?.status === 401) {
        // Handle authentication error
        localStorage.removeItem('token');
        window.location.href = '/login';
        return [];
      }
      console.error('Failed to fetch templates:', error);
      throw this.handleApiError(error, 'getTemplates');
    }
  }

  async getTemplate(id: string) {
    const response = await this.axios.get(`/templates/${id}`);
    return response.data;
  }

  async updateTemplate(id: string, data: any) {
    const response = await this.axios.put(`/templates/${id}`, data);
    return response.data;
  }

  async deleteTemplate(id: string) {
    const response = await this.axios.delete(`/templates/${id}`);
    return response.data;
  }

  async addToQuestionBank(data: any) {
    const response = await this.axios.post('/templates/question-bank', data);
    return response.data;
  }

  async getQuestionBankItems(params?: {
    creator?: string;
    categories?: string[];
    type?: string;
    search?: string;
  }) {
    try {
      const response = await this.axios.get('/templates/question-bank', {
        params: {
          ...params,
          search: params?.search?.trim() || undefined,
          categories: params?.categories?.length ? params.categories : undefined,
          type: params?.type || undefined,
          creator: params?.creator || undefined
        }
      });
      return response.data;
    } catch (error) {
      console.error('Failed to fetch question bank items:', error);
      return [];
    }
  }

  // Auth endpoints
  async logout() {
    await this.axios.post('/auth/logout');
  }

  async changePassword(currentPassword: string, newPassword: string) {
    try {
      const response = await this.axios.post('/auth/change-password', {
        currentPassword,
        newPassword
      });
      return response.data;
    } catch (error) {
      throw this.handleApiError(error, 'changePassword');
    }
  }

  async requestPasswordReset(email: string) {
    try {
      const response = await this.axios.post('/auth/request-password-reset', {
        email
      });
      return response.data;
    } catch (error) {
      throw this.handleApiError(error, 'requestPasswordReset');
    }
  }

  async resetPassword(email: string, resetToken: string, newPassword: string) {
    try {
      const response = await this.axios.post('/auth/reset-password', {
        email,
        resetToken,
        newPassword
      });
      return response.data;
    } catch (error) {
      throw this.handleApiError(error, 'resetPassword');
    }
  }

  async markNotificationAsRead(notificationId: string) {
    await this.axios.post(`/notifications/${notificationId}/read`);
  }

  async updateNotificationPreferences(preferences: { email?: boolean; push?: boolean }) {
    await this.axios.put('/notifications/preferences', preferences);
  }

  async getNotifications() {
    const response = await this.axios.get('/notifications');
    return response.data;
  }

  async updateProfile(data: { username?: string; email?: string; password?: string }) {
    const response = await this.axios.put('/users/profile', data);
    return response.data;
  }

  // Team endpoints
  async getTeams() {
    try {
      const response = await this.axios.get('/teams');
      // Ensure we always return an array of teams
      return Array.isArray(response.data) ? response.data : 
             Array.isArray(response.data.teams) ? response.data.teams : [];
    } catch (error) {
      console.error('Failed to fetch teams:', error);
      throw this.handleApiError(error, 'getTeams');
    }
  }
  
  async createTeam(data: { name: string; description?: string }) {
    try {
      const response = await this.axios.post('/teams', data);
      return response.data;
    } catch (error) {
      console.error('Failed to create team:', error);
      throw this.handleApiError(error, 'createTeam');
    }
  }
  
  async deleteTeam(teamId: string) {
    try {
      const response = await this.axios.delete(`/teams/${teamId}`);
      return response.data;
    } catch (error) {
      console.error('Failed to delete team:', error);
      throw this.handleApiError(error, 'deleteTeam');
    }
  }
  
  async updateTeam(teamId: string, data: { name?: string; description?: string }) {
    try {
      const response = await this.axios.put(`/teams/${teamId}`, data);
      return response.data;
    } catch (error) {
      console.error('Failed to update team:', error);
      throw this.handleApiError(error, 'updateTeam');
    }
  }
  
  async getTeam(teamId: string) {
    try {
      const response = await this.axios.get(`/teams/${teamId}`);
      return response.data;
    } catch (error) {
      console.error(`Failed to fetch team ${teamId}:`, error);
      throw this.handleApiError(error, 'getTeam');
    }
  }
  
  async addTeamMember(teamId: string, userId: string) {
    try {
      const response = await this.axios.post(`/teams/${teamId}/members/${userId}`);
      return response.data;
    } catch (error) {
      console.error('Failed to add team member:', error);
      throw this.handleApiError(error, 'addTeamMember');
    }
  }
  
  async removeTeamMember(teamId: string, memberId: string) {
    try {
      const response = await this.axios.delete(`/teams/${teamId}/members/${memberId}`);
      return response.data;
    } catch (error) {
      console.error('Failed to remove team member:', error);
      throw this.handleApiError(error, 'removeTeamMember');
    }
  }
  
  async getTeamSharedSurveys(teamId: string) {
    try {
      const response = await this.axios.get(`/teams/${teamId}/surveys`);
      return response.data;
    } catch (error) {
      console.error('Failed to fetch team shared surveys:', error);
      return { surveys: [] }; // Return empty surveys array as fallback
    }
  }
  
  async shareSurveyWithTeam(teamId: string, surveyId: string, permissions: {
    canEdit: boolean;
    canDelete: boolean;
    canViewResponses: boolean;
    canShare: boolean;
  }) {
    try {
      const response = await this.axios.post(`/teams/${teamId}/surveys/${surveyId}/share`, permissions);
      return response.data;
    } catch (error) {
      console.error('Failed to share survey with team:', error);
      throw this.handleApiError(error, 'shareSurveyWithTeam');
    }
  }

  async updateTeamSurveyPermissions(teamId: string, surveyId: string, permissions: {
    canEdit: boolean;
    canDelete: boolean;
    canViewResponses: boolean;
    canShare: boolean;
  }) {
    try {
      const response = await this.axios.put(`/teams/${teamId}/surveys/${surveyId}/permissions`, permissions);
      return response.data;
    } catch (error) {
      console.error('Failed to update survey permissions:', error);
      throw this.handleApiError(error, 'updateTeamSurveyPermissions');
    }
  }

  async removeSharedSurveyFromTeam(teamId: string, surveyId: string) {
    try {
      const response = await this.axios.delete(`/teams/${teamId}/surveys/${surveyId}`);
      return response.data;
    } catch (error) {
      console.error('Failed to remove survey from team:', error);
      throw this.handleApiError(error, 'removeSharedSurveyFromTeam');
    }
  }

  async inviteMember(teamId: string, data: { email: string; role?: string }) {
    try {
      const response = await this.axios.post(`/teams/${teamId}/invite`, data);
      return response.data;
    } catch (error) {
      console.error('Failed to invite team member:', error);
      throw this.handleApiError(error, 'inviteMember');
    }
  }
  
  async updateTeamMemberPermissions(teamId: string, memberId: string, permissions: TeamPermissions) {
    try {
      const response = await this.axios.put(`/teams/${teamId}/members/${memberId}/permissions`, permissions);
      return response.data;
    } catch (error) {
      console.error('Failed to update team member permissions:', error);
      throw this.handleApiError(error, 'updateTeamMemberPermissions');
    }
  }

  // Survey sharing endpoints
  async shareSurvey(surveyId: string, data: { teamId?: string; emails?: string[] }) {
    try {
      const response = await this.axios.post(`/surveys/${surveyId}/share`, data);
      return response.data;
    } catch (error) {
      this.handleApiError(error, 'shareSurvey');
    }
  }

  // File upload endpoint
  async uploadFile(questionId: string, file: File) {
    try {
      const formData = new FormData();
      formData.append('file', file);
      
      const response = await this.axios.post(
        `/responses/upload/${questionId}`, 
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      return response.data;
    } catch (error) {
      throw this.handleApiError(error, 'uploadFile');
    }
  }

  // Public survey endpoint - accessible without authentication
  async getPublicSurvey(id: string) {
    try {
      // Create a direct axios instance without authentication interceptors
      const apiUrl = process.env.REACT_APP_API_URL ;
      const publicAxios = axios.create({ baseURL: apiUrl });
      
      const response = await publicAxios.get(`/surveys/public/${id}`);
      return response.data;
    } catch (error) {
      console.error('Failed to fetch public survey:', error);
      throw this.handleApiError(error, 'getPublicSurvey');
    }
  }

  // Invitation endpoints
  async getUserInvitations() {
    try {
      const response = await this.axios.get('/invitations/user');
      return response.data.invitations || [];
    } catch (error) {
      console.error('Failed to fetch user invitations:', error);
      return [];
    }
  }

  async getInvitationByToken(token: string) {
    try {
      const response = await this.axios.get(`/invitations/${token}`);
      return response.data.invitation;
    } catch (error) {
      console.error('Failed to fetch invitation details:', error);
      throw this.handleApiError(error, 'getInvitationByToken');
    }
  }

  async acceptInvitation(token: string) {
    try {
      const response = await this.axios.post(`/invitations/accept/${token}`);
      return response.data;
    } catch (error) {
      console.error('Failed to accept invitation:', error);
      throw this.handleApiError(error, 'acceptInvitation');
    }
  }

  async rejectInvitation(token: string) {
    try {
      const response = await this.axios.post(`/invitations/reject/${token}`);
      return response.data;
    } catch (error) {
      console.error('Failed to reject invitation:', error);
      throw this.handleApiError(error, 'rejectInvitation');
    }
  }

  async getTeamInvitations(teamId: string) {
    try {
      const response = await this.axios.get(`/teams/${teamId}/invitations`);
      return response.data.invitations || [];
    } catch (error) {
      console.error('Failed to fetch team invitations:', error);
      return [];
    }
  }

  // Support ticket endpoint
  async createTicket(data: { subject: string; description: string; priority?: string }) {
    try {
      const response = await this.axios.post('/support/tickets', data);
      return response.data;
    } catch (error) {
      throw this.handleApiError(error, 'createTicket');
    }
  }

  // Manual AI Analysis trigger endpoint
  async triggerManualAnalysis(surveyId: string) {
    try {
      const response = await this.axios.post(`/surveys/analytics/${surveyId}/trigger-analysis`);
      return response.data;
    } catch (error) {
      console.error('Failed to trigger manual analysis:', error);
      throw this.handleApiError(error, 'triggerManualAnalysis');
    }
  }

  // Get manual analysis stats to show available triggers and unanalyzed responses
  async getManualAnalysisStats(surveyId: string) {
    try {
      const response = await this.axios.get(`/surveys/analytics/${surveyId}/analysis-stats`);
      return response.data;
    } catch (error) {
      console.error('Failed to get manual analysis stats:', error);
      // Return default values on error
      return {
        dailyTriggersUsed: 0,
        dailyTriggersRemaining: 3,
        pendingAnalysis: 0
      };
    }
  }
}