import React, { lazy, Suspense, useEffect, useState, useCallback } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider, CssBaseline, Alert, Snackbar } from '@mui/material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { createTheme } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { HelmetProvider } from 'react-helmet-async';
import { ApiClient } from './api/client'; // Fixed import - remove the braces
import { PrivateRoute } from './components/auth/PrivateRoute';
import { AuthProvider } from './contexts/AuthContext';
import { NotificationProvider } from './contexts/NotificationContext';
import { useAuth } from './hooks/useAuth';
import ScrollToTop from './components/common/ScrollToTop';
import './App.css';
import AccountSettings from './pages/AccountSettings';

// Lazy loaded components
const LandingPage = lazy(() => import('./pages/LandingPage'));
const Login = lazy(() => import('./pages/Login'));
const Register = lazy(() => import('./pages/Register'));
const ResetPassword = lazy(() => import('./pages/ResetPassword'));
const SocialAuthCallback = lazy(() => import('./pages/SocialAuthCallback')); // Add social auth callback component
const Dashboard = lazy(() => import('./pages/Dashboard'));
const Features = lazy(() => import('./pages/Features'));
const About = lazy(() => import('./pages/About'));
const Pricing = lazy(() => import('./pages/Pricing'));
const PrivacyPolicy = lazy(() => import('./pages/PrivacyPolicy'));
const TermsOfService = lazy(() => import('./pages/TermsOfService'));
const FAQ = lazy(() => import('./pages/FAQ'));
const Resources = lazy(() => import('./pages/Resources'));
const BlogArticle = lazy(() => import('./pages/BlogArticle')); // New import for blog articles
const Enterprise = lazy(() => import('./pages/Enterprise'));
const CreateSurvey = lazy(() => import('./pages/CreateSurvey'));
const Analytics = lazy(() => import('./pages/Analytics'));
const ViewResults = lazy(() => import('./pages/ViewResults'));
const Templates = lazy(() => import('./pages/Templates'));
const Teams = lazy(() => import('./pages/Teams'));
const TeamDetailsPage = lazy(() => import('./pages/Teams/TeamDetailsPage'));
const TakeSurvey = lazy(() => import('./pages/TakeSurvey'));
const InvitationPage = lazy(() => import('./pages/InvitationPage'));
const Integrations = lazy(() => import('./pages/Integrations'));
const ApiDocs = lazy(() => import('./pages/ApiDocs'));
const Support = lazy(() => import('./pages/Support'));
const ProfilePage = lazy(() => import('./pages/Profile'));
const Billing = lazy(() => import('./pages/billing/Billing'));
const BillingSuccess = lazy(() => import('./pages/billing/BillingSuccess'));
const BillingCancel = lazy(() => import('./pages/billing/BillingCancel'));

// Layouts
const PublicLayout = lazy(() => import('./layouts/PublicLayout'));
const AuthenticatedLayout = lazy(() => import('./layouts/AuthenticatedLayout'));

// Error Boundary Component
class ErrorBoundary extends React.Component<{ children: React.ReactNode }, { hasError: boolean }> {
  constructor(props: { children: React.ReactNode }) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_: Error) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.error('Error caught by ErrorBoundary:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div style={{ padding: '2rem', textAlign: 'center' }}>
          <h2>Something went wrong.</h2>
          <p>Please try refreshing the page or contact support if the issue persists.</p>
          <button 
            onClick={() => this.setState({ hasError: false })}
            style={{ 
              padding: '0.5rem 1rem', 
              backgroundColor: '#1976d2', 
              color: 'white', 
              border: 'none', 
              borderRadius: '4px', 
              cursor: 'pointer' 
            }}
          >
            Try Again
          </button>
        </div>
      );
    }

    return this.props.children;
  }
}

// Create the primary theme
const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: '#dc004e',
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontSize: '2.5rem',
    },
    h2: {
      fontSize: '2rem',
    },
  },
});

// Create React Query client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount, error: any) => {
        // Don't retry if we explicitly marked the error to stop retrying
        if (error.shouldStopRetrying) return false;
        // Otherwise retry up to 2 times
        return failureCount < 2;
      },
      staleTime: 5 * 60 * 1000, // Consider data stale after 5 minutes
      gcTime: 10 * 60 * 1000, // Keep unused data in cache for 10 minutes
      refetchOnWindowFocus: false, // Don't refetch on window focus
      refetchOnReconnect: true, // Refetch on reconnect
    },
  },
});

function App() {
  const [isBackendConnected, setIsBackendConnected] = useState<boolean | null>(null);
  const [showConnectionError, setShowConnectionError] = useState(false);
  const apiClient = ApiClient.getInstance();

  const checkBackendConnection = useCallback(async () => {
    try {
      const isConnected = await apiClient.checkConnection();
      setIsBackendConnected(isConnected);
      setShowConnectionError(!isConnected);
    } catch (err) {
      console.error('Error checking backend connection:', err);
      setIsBackendConnected(false);
      setShowConnectionError(true);
    }
  }, [apiClient]);

  useEffect(() => {
    checkBackendConnection();
    const intervalId = setInterval(checkBackendConnection, 60000);
    return () => clearInterval(intervalId);
  }, [checkBackendConnection]);

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <HelmetProvider>
            <AuthProvider>
              <NotificationProvider>
                {/* Show backend connection error only if explicitly disconnected */}
                {isBackendConnected === false && (
                  <Snackbar 
                    open={showConnectionError} 
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    autoHideDuration={null}
                  >
                    <Alert 
                      severity="error" 
                      variant="filled"
                      onClose={() => setShowConnectionError(false)}
                    >
                      Cannot connect to the backend server. Please check your internet connection or try again later.
                    </Alert>
                  </Snackbar>
                )}
                
                <BrowserRouter>
                  <ScrollToTop />
                  <Suspense fallback={<div>Loading...</div>}>
                    <Routes>
                      {/* Marketing Pages */}
                      <Route path="/" element={
                        <ErrorBoundary>
                          <PublicLayout>
                            <LandingPage />
                          </PublicLayout>
                        </ErrorBoundary>
                      } />
                      <Route path="/features" element={
                        <ErrorBoundary>
                          <PublicLayout>
                            <Features />
                          </PublicLayout>
                        </ErrorBoundary>
                      } />
                      <Route path="/pricing" element={
                        <ErrorBoundary>
                          <PublicLayout>
                            <Pricing />
                          </PublicLayout>
                        </ErrorBoundary>
                      } />
                      <Route path="/resources" element={
                        <ErrorBoundary>
                          <PublicLayout>
                            <Resources />
                          </PublicLayout>
                        </ErrorBoundary>
                      } />
                      {/* Blog Article Route */}
                      <Route path="/blog/:slug" element={
                        <ErrorBoundary>
                          <PublicLayout>
                            <BlogArticle />
                          </PublicLayout>
                        </ErrorBoundary>
                      } />
                      <Route path="/enterprise" element={
                        <ErrorBoundary>
                          <PublicLayout>
                            <Enterprise />
                          </PublicLayout>
                        </ErrorBoundary>
                      } />
                      <Route path="/about" element={
                        <ErrorBoundary>
                          <PublicLayout>
                            <About />
                          </PublicLayout>
                        </ErrorBoundary>
                      } />
                      <Route path="/privacy" element={
                        <ErrorBoundary>
                          <PublicLayout>
                            <PrivacyPolicy />
                          </PublicLayout>
                        </ErrorBoundary>
                      } />
                      <Route path="/terms" element={
                        <ErrorBoundary>
                          <PublicLayout>
                            <TermsOfService />
                          </PublicLayout>
                        </ErrorBoundary>
                      } />
                      <Route path="/faq" element={
                        <ErrorBoundary>
                          <PublicLayout>
                            <FAQ />
                          </PublicLayout>
                        </ErrorBoundary>
                      } />

                      <Route path="/login" element={
                        <ErrorBoundary>
                          <PublicLayout>
                            <Login />
                          </PublicLayout>
                        </ErrorBoundary>
                      } />
                      <Route path="/register" element={
                        <ErrorBoundary>
                          <PublicLayout>
                            <Register />
                          </PublicLayout>
                        </ErrorBoundary>
                      } />
                      <Route path="/reset-password" element={
                        <ErrorBoundary>
                          <PublicLayout>
                            <ResetPassword />
                          </PublicLayout>
                        </ErrorBoundary>
                      } />
                      <Route path="/auth/social-callback" element={
                        <ErrorBoundary>
                          <PublicLayout>
                            <SocialAuthCallback />
                          </PublicLayout>
                        </ErrorBoundary>
                      } />
                      {/* Basic user routes - accessible with any role */}
                      <Route path="/dashboard" element={
                        <ErrorBoundary>
                          <PrivateRoute>
                            <AuthenticatedLayout>
                              <Dashboard />
                            </AuthenticatedLayout>
                          </PrivateRoute>
                        </ErrorBoundary>
                      } />
                      <Route path="/profile" element={
                        <ErrorBoundary>
                          <PrivateRoute>
                            <AuthenticatedLayout>
                              <AccountSettings />
                            </AuthenticatedLayout>
                          </PrivateRoute>
                        </ErrorBoundary>
                      } />
                      {/* Content creation routes - accessible with 'user', 'creator', or 'admin' roles */}
                      <Route path="/create" element={
                        <ErrorBoundary>
                          <PrivateRoute requiredRoles={['user', 'creator', 'admin']}>
                            <AuthenticatedLayout>
                              <CreateSurvey />
                            </AuthenticatedLayout>
                          </PrivateRoute>
                        </ErrorBoundary>
                      } />
                      <Route path="/edit/:id" element={
                        <ErrorBoundary>
                          <PrivateRoute requiredRoles={['user', 'creator', 'admin']}>
                            <AuthenticatedLayout>
                              <CreateSurvey />
                            </AuthenticatedLayout>
                          </PrivateRoute>
                        </ErrorBoundary>
                      } />
                      <Route path="/analytics/:id" element={
                        <ErrorBoundary>
                          <PrivateRoute requiredRoles={['user', 'creator', 'admin']}>
                            <AuthenticatedLayout>
                              <Analytics />
                            </AuthenticatedLayout>
                          </PrivateRoute>
                        </ErrorBoundary>
                      } />
                      <Route path="/results/:id" element={
                        <ErrorBoundary>
                          <PrivateRoute requiredRoles={['user', 'creator', 'admin']}>
                            <AuthenticatedLayout>
                              <ViewResults />
                            </AuthenticatedLayout>
                          </PrivateRoute>
                        </ErrorBoundary>
                      } />
                      {/* Templates and team management - restrict based on role if needed */}
                      <Route path="/templates" element={
                        <ErrorBoundary>
                          <PrivateRoute>
                            <AuthenticatedLayout>
                              <Templates />
                            </AuthenticatedLayout>
                          </PrivateRoute>
                        </ErrorBoundary>
                      } />
                      <Route path="/teams" element={
                        <ErrorBoundary>
                          <PrivateRoute>
                            <AuthenticatedLayout>
                              <Teams />
                            </AuthenticatedLayout>
                          </PrivateRoute>
                        </ErrorBoundary>
                      } />
                      <Route path="/teams/:id" element={
                        <ErrorBoundary>
                          <PrivateRoute>
                            <AuthenticatedLayout>
                              <TeamDetailsPage />
                            </AuthenticatedLayout>
                          </PrivateRoute>
                        </ErrorBoundary>
                      } />
                      {/* Public route for taking surveys */}
                      <Route path="/survey/:id" element={
                        <ErrorBoundary>
                          <PublicLayout>
                            <TakeSurvey />
                          </PublicLayout>
                        </ErrorBoundary>
                      } />
                      {/* Public route for taking surveys from shared links */}
                      <Route path="/take/:id" element={
                        <ErrorBoundary>
                          <PublicLayout>
                            <TakeSurvey />
                          </PublicLayout>
                        </ErrorBoundary>
                      } />
                      {/* Invitation routes */}
                      <Route path="/invitations/:token" element={
                        <ErrorBoundary>
                          <PublicLayout>
                            <InvitationPage />
                          </PublicLayout>
                        </ErrorBoundary>
                      } />
                      <Route path="/invitations/accept/:token" element={
                        <ErrorBoundary>
                          <PublicLayout>
                            <InvitationPage />
                          </PublicLayout>
                        </ErrorBoundary>
                      } />
                      <Route path="/invitations/reject/:token" element={
                        <ErrorBoundary>
                          <PublicLayout>
                            <InvitationPage />
                          </PublicLayout>
                        </ErrorBoundary>
                      } />
                      {/* Integration and API Routes */}
                      <Route path="/integrations" element={
                        <ErrorBoundary>
                          <AuthenticatedLayout>
                            <Integrations />
                          </AuthenticatedLayout>
                        </ErrorBoundary>
                      } />
                      <Route path="/api-docs" element={
                        <ErrorBoundary>
                          <PublicLayout>
                            <ApiDocs />
                          </PublicLayout>
                        </ErrorBoundary>
                      } />
                      {/* Support Routes */}
                      <Route path="/support" element={
                        <ErrorBoundary>
                          <AuthenticatedLayout>
                            <Support />
                          </AuthenticatedLayout>
                        </ErrorBoundary>
                      } />
                      <Route path="/contact" element={
                        <ErrorBoundary>
                          <PublicLayout>
                            <Support />
                          </PublicLayout>
                        </ErrorBoundary>
                      } />
                      
                      {/* Billing Routes */}
                      <Route path="/billing" element={
                        <ErrorBoundary>
                          <PrivateRoute>
                            <AuthenticatedLayout>
                              <Billing />
                            </AuthenticatedLayout>
                          </PrivateRoute>
                        </ErrorBoundary>
                      } />
                      <Route path="/billing/success" element={
                        <ErrorBoundary>
                          <PrivateRoute>
                            <AuthenticatedLayout>
                              <BillingSuccess />
                            </AuthenticatedLayout>
                          </PrivateRoute>
                        </ErrorBoundary>
                      } />
                      <Route path="/billing/cancel" element={
                        <ErrorBoundary>
                          <PrivateRoute>
                            <AuthenticatedLayout>
                              <BillingCancel />
                            </AuthenticatedLayout>
                          </PrivateRoute>
                        </ErrorBoundary>
                      } />
                      
                      <Route path="*" element={<Navigate to="/" replace />} />
                    </Routes>
                  </Suspense>
                </BrowserRouter>
              </NotificationProvider>
            </AuthProvider>
          </HelmetProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;