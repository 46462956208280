import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import {ApiClient} from '../api/client';
import WebSocketService, { WebSocketEvent } from '../services/WebSocketService';
import { useAuth } from '../hooks/useAuth';

export type NotificationType = 'success' | 'info' | 'warning' | 'error';

export interface Notification {
  id: string;
  message: string;
  type: NotificationType;
  title?: string;
  read: boolean;
  timestamp: Date;
  link?: string;
  data?: any;
}

interface NotificationContextProps {
  notifications: Notification[];
  unreadCount: number;
  showNotification: (notification: Omit<Notification, 'id' | 'timestamp' | 'read'>) => void;
  markAsRead: (id: string) => void;
  markAllAsRead: () => void;
  clearNotifications: () => void;
}

const NotificationContext = createContext<NotificationContextProps | undefined>(undefined);

export const useNotifications = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error('useNotifications must be used within a NotificationProvider');
  }
  return context;
};

export const NotificationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const { user, isAuthenticated } = useAuth();
  const apiClient = ApiClient.getInstance();
  
  // Calculate unread count
  const unreadCount = notifications.filter(n => !n.read).length;

  // Add a new notification
  const showNotification = useCallback((notification: Omit<Notification, 'id' | 'timestamp' | 'read'>) => {
    const newNotification: Notification = {
      ...notification,
      id: Date.now().toString(),
      timestamp: new Date(),
      read: false,
    };

    setNotifications(prev => [newNotification, ...prev]);
  }, []);

  // Mark notification as read
  const markAsRead = useCallback(async (id: string) => {
    try {
      await apiClient.markNotificationAsRead(id);
      setNotifications(prev =>
        prev.map(notification =>
          notification.id === id ? { ...notification, read: true } : notification
        )
      );
    } catch (error) {
      console.error('Failed to mark notification as read:', error);
    }
  }, []);

  // Mark all notifications as read
  const markAllAsRead = useCallback(() => {
    setNotifications(prev =>
      prev.map(notification => ({ ...notification, read: true }))
    );
  }, []);

  // Clear all notifications
  const clearNotifications = useCallback(() => {
    setNotifications([]);
  }, []);

  // Handle WebSocket events
  useEffect(() => {
    if (isAuthenticated && user) {
      const wsService = WebSocketService.getInstance();
      wsService.connect(localStorage.getItem('token') || '');

      const handleSurveyResponse = (data: any) => {
        showNotification({
          message: `New response received for survey: ${data.surveyTitle}`,
          type: 'info',
          title: 'New Survey Response',
          link: `/results/${data.surveyId}`,
          data,
        });
      };

      const handleTeamInvite = (data: any) => {
        showNotification({
          message: `You've been invited to join team: ${data.teamName}`,
          type: 'success',
          title: 'Team Invitation',
          link: `/teams`,
          data,
        });
      };

      const handleBadgeEarned = (data: any) => {
        showNotification({
          message: `You've earned the ${data.badgeName} badge!`,
          type: 'success',
          title: 'Badge Earned',
          link: `/profile`,
          data,
        });
      };

      const handleLevelUp = (data: any) => {
        showNotification({
          message: `Congratulations! You've reached level ${data.level}!`,
          type: 'success',
          title: 'Level Up',
          link: `/profile`,
          data,
        });
      };

      wsService.subscribe(WebSocketEvent.SURVEY_RESPONSE, handleSurveyResponse);
      wsService.subscribe(WebSocketEvent.TEAM_INVITE, handleTeamInvite);
      wsService.subscribe(WebSocketEvent.BADGE_EARNED, handleBadgeEarned);
      wsService.subscribe(WebSocketEvent.LEVEL_UP, handleLevelUp);

      return () => {
        wsService.unsubscribe(WebSocketEvent.SURVEY_RESPONSE, handleSurveyResponse);
        wsService.unsubscribe(WebSocketEvent.TEAM_INVITE, handleTeamInvite);
        wsService.unsubscribe(WebSocketEvent.BADGE_EARNED, handleBadgeEarned);
        wsService.unsubscribe(WebSocketEvent.LEVEL_UP, handleLevelUp);
        wsService.disconnect();
      };
    }
  }, [isAuthenticated, user, showNotification]);

  return (
    <NotificationContext.Provider
      value={{
        notifications,
        unreadCount,
        showNotification,
        markAsRead,
        markAllAsRead,
        clearNotifications,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};