export enum WebSocketEvent {
  CONNECT = 'connect',
  DISCONNECT = 'disconnect',
  RECONNECT = 'reconnect',
  SURVEY_RESPONSE = 'survey_response',
  TEAM_INVITE = 'team_invite',
  BADGE_EARNED = 'badge_earned',
  LEVEL_UP = 'level_up',
  ANNOUNCEMENT = 'announcement',
  ERROR = 'error'
}

type MessageCallback = (data: any) => void;

interface Message {
  event: WebSocketEvent;
  data: any;
}

class WebSocketService {
  private static instance: WebSocketService;
  private socket: WebSocket | null = null;
  private subscriptions: Map<WebSocketEvent, Set<MessageCallback>> = new Map();
  private connected: boolean = false;
  private messageQueue: Message[] = [];
  private reconnectAttempts: number = 0;
  private maxReconnectAttempts: number = 5;
  private reconnectTimeout: number = 2000;
  private pingInterval: NodeJS.Timeout | null = null;
  private baseUrl: string;

  private constructor() {
    // Use localhost:3001 for development, can be overridden by environment variable
    this.baseUrl = process.env.REACT_APP_WS_URL ||'';
  }

  public static getInstance(): WebSocketService {
    if (!WebSocketService.instance) {
      WebSocketService.instance = new WebSocketService();
    }
    return WebSocketService.instance;
  }

  public connect(token: string): void {
    if (this.socket) {
      this.disconnect();
    }

    try {
      this.socket = new WebSocket(`${this.baseUrl}?token=${token}`);
      this.setupEventListeners();
    } catch (error) {
      console.error('WebSocket connection error:', error);
      this.notifySubscribers(WebSocketEvent.ERROR, { error: 'Failed to connect to WebSocket server' });
      this.handleReconnect();
    }
  }

  public disconnect(): void {
    if (this.socket) {
      this.socket.close();
      this.socket = null;
      this.connected = false;
      this.stopPingInterval();
    }
  }

  public subscribe(event: WebSocketEvent, callback: MessageCallback): void {
    if (!this.subscriptions.has(event)) {
      this.subscriptions.set(event, new Set());
    }
    this.subscriptions.get(event)?.add(callback);
  }

  public unsubscribe(event: WebSocketEvent, callback: MessageCallback): void {
    if (this.subscriptions.has(event)) {
      this.subscriptions.get(event)?.delete(callback);
      if (this.subscriptions.get(event)?.size === 0) {
        this.subscriptions.delete(event);
      }
    }
  }

  public send(event: WebSocketEvent, data: any): void {
    if (!this.connected) {
      this.queueMessage(event, data);
      return;
    }

    try {
      this.socket?.send(JSON.stringify({ event, data }));
    } catch (error) {
      console.error('Failed to send message:', error);
      this.queueMessage(event, data);
    }
  }

  private setupEventListeners(): void {
    if (!this.socket) return;

    this.socket.onopen = () => {
      this.connected = true;
      this.reconnectAttempts = 0;
      this.startPingInterval();
      this.processMessageQueue();
      this.notifySubscribers(WebSocketEvent.CONNECT, { timestamp: new Date() });
    };

    this.socket.onmessage = (event) => {
      try {
        const message = JSON.parse(event.data);
        if (message && message.event) {
          this.notifySubscribers(message.event as WebSocketEvent, message.data);
        }
      } catch (error) {
        console.error('Failed to parse websocket message:', error);
        this.notifySubscribers(WebSocketEvent.ERROR, { 
          error: 'Failed to parse message', 
          details: error 
        });
      }
    };

    this.socket.onclose = () => {
      this.connected = false;
      this.stopPingInterval();
      this.notifySubscribers(WebSocketEvent.DISCONNECT, { timestamp: new Date() });
      this.handleReconnect();
    };

    this.socket.onerror = (error) => {
      console.error('WebSocket error:', error);
      this.notifySubscribers(WebSocketEvent.ERROR, { 
        error: 'WebSocket connection error', 
        details: error 
      });
    };
  }

  private notifySubscribers(event: WebSocketEvent, data: any): void {
    const callbacks = this.subscriptions.get(event);
    if (callbacks) {
      callbacks.forEach(callback => {
        try {
          callback(data);
        } catch (error) {
          console.error(`Error in callback for event ${event}:`, error);
        }
      });
    }
  }

  private handleReconnect(): void {
    if (this.reconnectAttempts >= this.maxReconnectAttempts) {
      console.warn('Maximum reconnection attempts reached');
      this.notifySubscribers(WebSocketEvent.ERROR, { 
        error: 'Failed to reconnect after maximum attempts' 
      });
      return;
    }

    this.reconnectAttempts++;
    const token = localStorage.getItem('token');
    
    if (!token) {
      console.warn('No token found for reconnection');
      this.notifySubscribers(WebSocketEvent.ERROR, { 
        error: 'No authentication token found for reconnection' 
      });
      return;
    }

    setTimeout(() => {
      this.notifySubscribers(WebSocketEvent.RECONNECT, { 
        attempt: this.reconnectAttempts,
        timestamp: new Date() 
      });
      this.connect(token);
    }, this.reconnectTimeout * this.reconnectAttempts);
  }

  private queueMessage(event: WebSocketEvent, data: any): void {
    this.messageQueue.push({ event, data });
  }

  private processMessageQueue(): void {
    while (this.messageQueue.length > 0) {
      const message = this.messageQueue.shift();
      if (message) {
        this.send(message.event, message.data);
      }
    }
  }

  private startPingInterval(): void {
    this.pingInterval = setInterval(() => {
      this.send(WebSocketEvent.CONNECT, { ping: true });
    }, 30000); // Send ping every 30 seconds
  }

  private stopPingInterval(): void {
    if (this.pingInterval) {
      clearInterval(this.pingInterval);
      this.pingInterval = null;
    }
  }
}

export default WebSocketService;